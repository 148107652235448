const browserLang = window.navigator.language;
let lang = localStorage.getItem('lang') || null;

if (!lang) {
  if(browserLang === 'nl' || browserLang === 'nl-BE'){
    lang = 'nl';
  }
  else if (browserLang === 'fr' || browserLang === 'fr-FR' || browserLang === 'fr-BE') {
    lang = 'fr';
  }
  else {
    lang = 'en';
  }

  localStorage.setItem('lang', lang);
}

window.location.href = `/${lang}/index.html`;
